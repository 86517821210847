/*
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}*/

/* Table of Content
----------------------------------------
  #General Styling
  #Main Menu
  #Work Section
  #Side Menu
  #Services
  #About
  #Rates
  #Team
  #Contact
  #Intro Fonts
  #Social Icons
  #Footer
  #Media Queries
*/
body {
  overflow: auto;
  margin: 0px;
}

#custom {
  position: fixed;
  width: 150px;
  height: 270px;
  top: 25%;
  left: -150px;
  background-color: #212121;
  z-index: 1000;
}

#custom li {
  margin-top: 3px;
}

#custom li:first-child {
  margin-top: -5px;
  padding-left: 12px;
  border-top: 5px solid #fff;
  padding-top: 3px;
}

#custom img {
  width: 150px;
  margin-top:5px;
}

#optionheading {
  font-size: 7px;
  font-style: normal;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  margin-top: 20px;
}

#custom a {
  text-decoration: none;
  font-style: normal;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  margin-left: 11px;
}

#custom a:hover {
  color: #98BF0B;
}

#close {
  width: 20px;
  height:23px;
  display: block;
  left: 150px;
  top: 0px;
  position: absolute;
  background-color: #212121;
  cursor: pointer;
}

#customlogo {
  width:30px;
  height: 30px;
  margin-left: 9px;
  margin-top: 3px;
}

/****************** #General Styling *******************/

body {
  font-size: 12px;
  font-family: 'Georgia';
  //color: #868686;
  line-height: 23px;
  position: relative;
  font-style: italic;
  overflow-x: hidden;
}

.clear100 {
  height:100px;
  clear: both;
}

.clear40 {
  height:40px;
  clear: both;
}

.container {
  width: 1000px;
  height: auto;
  margin: 0 auto;
  padding: 30px 0 10px 0;
  text-align: center;
}

p {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-size: 13px;
}

#inicio {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
}

#logo {
  width: 210px;
  height: 45px;
  margin: 0 auto;
  padding-top: 17px;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 50px;
  color: #272928;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 15px;
  line-height: 1.1em;
  text-transform: uppercase;
}

h1 span {
  font-weight: 300;
}

h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 9px;
  color: #000000;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-size: 10px;
  color: white;
  background-color: #595959;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  display: inline-block;
  padding: 3px 17px;
}

h3:hover {
  background: #444;
}


h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-style: italic;
  color: #868686;
  font-weight: normal;
}

h5 {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #FFF;
  line-height: 23px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  padding-top: 4px;
}

h7 {
  font-size: 29px;
  color: #2e2e2e;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.smallheadingfont {
  font-size: 29px;
  color: #2e2e2e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  display: inline;
  text-transform: uppercase;
}


.smallheadingfont span {
  font-weight: 300;
}

h8 {
  font-size: 20px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  float: left;
  font-style: normal;
}

.smallheadingfont8 {
  font-size: 20px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  float: left;
  font-style: normal;
}

h9 {
  font-size: 15px;
  color: #000;
  font-family: 'arial';
  font-weight: normal;
  text-transform: uppercase;
  font-style: normal;
  margin-bottom: 20px;
}

.headingfont9 {
  font-size: 15px;
  color: #FFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-style: normal;
  margin-bottom: 15px;
}


#inicioband {
  width: 100%;
  height: 75px;
  background-color: #fff;
  position: relative;
  //top: 6px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,.5);
  z-index: -10;
  background: #fff url('diagonal-lines.png') repeat left top;
}

#menuband {
  width: 550px;
  height: 35px;
  background-color: #fff;
  margin: 0 auto;
  //margin-top: 6px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,.5);
  z-index: 100;
  background: #fff url('diagonal-lines.png') repeat left top;
  color: #c8c8c8;
}

#menucover {
  width: 550px;
  height: 40px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 6px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,.5);
}


/****************** #Main Menu *******************/

#menu1 {
  width: 550px;
  display: inline-block;
  text-align: center;
  font-family: 'arial';
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 5px;
  padding: 0px;
}


#menu1 li {
  display: inline;
  text-decoration: none;
  color: #000;
  font-style: normal;
  margin-left: 30px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #4d4d4d;
}

#menu1 li:first-child {
  margin-left: 0px;
}

#menu1 li a {
  display: inline;
  text-decoration: none;
  font-style: normal;
  color: #000;
}

#navenclosure {
  width: 100%;
}

#iniciomenu {
  width: 650px;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  /**padding left: 0px;**/
  margin: 0 auto;
  color: #FFF;
  font-size: 13px;
  text-transform: uppercase;
  font-style: normal;
  font-family: 'Helvetica';
  font-weight: 900;
  letter-spacing: 0.3em;
  /**box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);**/
  text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
  border-top: 3px double #fff;
  border-bottom: 3px double #fff;
}

#iniciomenu select {
  display: none;
}

#iniciodrop{
  display: none;
}

#iniciomenu ul li {
  display: inline;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  text-decoration: none;
  color: #FFF;
  /**padding-right: 30px;**/
  cursor: pointer;
  margin-right: 35px;
}

#hiddenmenu ul li {
  display: inline;
  text-decoration: none;
  color: #000;
  /**padding-right: 45px;**/
  display: inline-block;
  cursor: pointer;
  margin-left: 45px;
}

#iniciomenu a {
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  text-decoration: none;
  color: #FFF;
  /**padding-right: 30px;**/
  cursor: pointer;
}

#iniciomenu a:hover {
  color: #000;
}

#iniciomenu ul li:last-child {

  margin-right: 0px;
  padding-right: 0px;
}

#hiddenmenu {
  width: 100%;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  /**padding left: 0px;**/
  margin: 0 auto;
  color: #000;
  font-size: 10px;
  text-transform: uppercase;
  font-style: normal;
  font-family: 'arial', sans-serif;
  font-weight: 900;
  letter-spacing: 0.3em;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  z-index:999;
  text-align: center;
  background: #fff url('diagonal-lines.png') repeat left top;
}

#hiddenmenu a {
  text-decoration: none;
  color: #4d4d4d;
  /**padding-right: 45px;**/
  display: inline-block;
  cursor: pointer;
}

#hiddenmenu a:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

#hiddennavenclosure {
  width: 100%;
  margin-top: 49px;
  background-color: #fff;

}

#hiddenmenu select {
  display: none;
}

#menuband select {
  display: none;
}

#navcover {
  width: 100%;
  background-color: #F6F6F6;
  height:49px;
  margin-top:-43px;
  position: relative;
}



/****************** #Intro Font ****************/

#intro {
  margin: 0 auto;
  margin-top:13%;
}

.introh1 {
  font-family: 'Bebas', sans-serif;
  font-size: 150px;
  color: #FFF;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top:-10px;
  line-height: 1.1em;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
}

.introh3 {
  font-family: 'Bebas', sans-serif;
  font-size: 32px;
  color: white;
  margin: 0 auto;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
  letter-spacing: 1px;
}

.introh4 {
  font-family: 'Bebas', sans-serif;
  font-size: 52px;
  width: 730px;
  margin: 0 auto;
  margin-top: -15px;
  padding-top: 23px;
  padding-bottom: 20px;
  font-weight: normal;
  border-top: 2px dotted #fff;
  border-bottom: 2px dotted #fff;
  color: white;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
}

.dottedline_left {
  width: 260px;
  border-top:2px dotted #FFF;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 15px;
}

.dottedline_right {
  width: 260px;
  border-top:2px dotted #FFF;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 15px;
}


#iniciofont {
  margin: 0 auto;
  width:600px;
}

/****************** #Social Icons *************/

.socialicon {
  display: inline;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
}

.topsocialicons {
  width: 300px;
  height:50px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  margin: 0 auto;
  margin-top:20px;
}

.contactsocialicons {
  width: 250px;
  height: 50px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
}

.contactsocialicon {
  margin-top: 2px;
  margin-left: 2px;
}

.contactsocialicon:hover {
  opacity: .8;
}

.contactsocialicon2 {
  margin-top: 2px;
  margin-left: 0px;
}

.contactsocialicon2:hover {
  opacity: .8;
}


.socialicon img {
  padding-top: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  padding-right: 5px;
}

.socialicon img:hover {
  top: -3px;
  opacity: .8;
}

.circle {
  width: 36px;
  height: 36px;
  background-color: #FFF;
  border-radius: 20px;
  float:left;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}


.circlelarge {
  width: 46px;
  height: 46px;
  background-color: #FFF;
  border-radius: 25px;
  float:left;
  //margin-right: 17px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  margin: 0px 10%;
}

.circlelarge:hover {
  padding: 2px;
  margin: 0px 10%;
}

.circlelarge:last-child {
  margin-right: 0px;
}

.circle2 {
  width: 33px;
  height: 33px;
  background-color: #FFF;
  border-radius: 20px;
  float:left;
  margin-right: 10px;
}


.circle:hover {
  padding: 2px;
}

#facebookicon {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #3B5998;
}

#facebookicon:hover {
  margin-top:-2px;
  margin-right: 8px;
  margin-left:-2px;
}

#twittericon {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #34CCFE;
}

#twittericon:hover {
  margin-top:-2px;
  margin-right: 8px;
  margin-left:-2px;
}

#behanceicon {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #696F75;
}

#behanceicon:hover {
  margin-top:-2px;
  margin-right: 8px;
  margin-left:-2px;

}

#dribbbleicon {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #EA4C89;
}

#dribbbleicon:hover {
  margin-top:-2px;
  margin-right: 8px;
  margin-left:-2px;
}

#linkedinicon {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #3282A0;
}

#linkedinicon:hover {
  margin-top:-2px;
  margin-left:-2px;
}

/**** Large Icons ****/

#facebookiconlarge {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #3B5998;
}

/* #facebookiconlarge:hover {
  margin-top:-2px;
  margin-right: 15px;
  margin-left:-2px;
} */

#twittericonlarge {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #34CCFE;
}

/* #twittericonlarge:hover {
  margin-top:-2px;
  margin-right: 15px;
  margin-left:-2px;
} */

#behanceiconlarge {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #696F75;
}

#behanceiconlarge:hover {
  margin-top:-2px;
  margin-right: 15px;
  margin-left:-2px;
}

#dribbbleiconlarge {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #EA4C89;
}

#dribbbleiconlarge:hover {
  margin-top:-2px;
  margin-right: 15px;
  margin-left:-2px;
}

#linkediniconlarge {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  background-color: #3282A0;
}

/* #linkediniconlarge:hover {
  margin-top:-2px;
  margin-left:-2px;
} */



/****************** #Footer *******************/

#footer {
  width: 100%;
  height: auto;
  //background-color: #F6F6F6;
  margin-top: -11px;
}


/* #Media Queries
================================================== */
@media only screen and (max-width: 400px) {
  .container {width: 300px;}
  .galleryitem {width: 300px; float: none; padding-right: 0px; padding-left: 0px;}
  .servicesitem {width: 300px; float: none;}
  #hiddenmenu { font-size: 6px;}
  #logo img {height: 33px; width: 160px;}
  #logo {height: 33px; width: 160px; padding-top: 8px;}
  #clients { display: none; }
  #testimonials { width: 300px;}
  #whatwedo {width: 300px;}
  #process {width: 300px;}
  #stats { width: 300px;}
  .portrait {margin: 0 auto; float: none;}
  .blurb {width: 300px;}
  .blurbenclosure{width: 300px;}
  .blurbenclosure2{width: 300px;}
  .aboutusblurb {width: 300px;}
  .contactleft {margin-left: 0px;}
  .contact_middle {width: 300px; margin: 0 auto; float: none; padding-left: 0px; padding-right: 0px;}
  #textleft {width: 300px; float: none; text-align: center; margin: 0 auto; border: none; padding-bottom: 30px; padding-right: 0px;}
  #textright {width: 300px; float: none; text-align: center; margin: 0 auto; border: none; padding-left: 0px;}
  #testimonials_split_text {width: 300px;}
  .line { width: 300px;}
  .aboutusblurb {width: 300px;}
  #skillwrapper {width: 300px; padding-bottom: 45px; margin: 0 auto;}
  #aboutusright {width: 300px; float: none;}
  #aboutusleft {width: 300px; float: none;}
  #contactbutton {width: 300px; margin: 0 auto;}
  #iniciomenu { width: 300px; font-size: 7px; border:none; margin-bottom:0px; margin-top:0px;}
  #iniciomenu ul {display: none;}
  .introh1 {font-size: 60px; margin-bottom: 9px; margin-bottom: 9px;}
  .introh3 {font-size: 16px; margin-bottom: 0px;}
  .introh4 {width: 300px; font-size: 21px; padding-bottom: 0px; padding-top: 4px;}
  #intro {margin-top: 25%;}
  h1 {font-size: 60px;}
  #hiddenmenu {margin-top: 44px;}
  #navcover {height: 35px;}
  .lineleft1 {width: 280px; margin-left: 0px; margin-right: 0px;}
  .lineright1 {width: 280px; margin-right: 0px; margin-left: 0px;}
  .lineleft2 {width: 280px; margin-left: 0px; margin-right: 0px;}
  .lineright2 {width: 280px; margin-right: 0px; margin-left: 0px;}
  .lineleft3 {width: 280px; margin-left: 0px; margin-right: 0px;}
  .lineright3 {width: 280px; margin-right: 0px; margin-left: 0px;}
  .lineleft4 {width: 280px;margin-left: 0px; margin-right: 0px;}
  .lineright4 {width: 280px; margin-right: 0px; margin-left: 0px;}
  .lineleft5 {width: 260px;margin-left: 0px; margin-right: 0px;}
  .lineright5 {width: 260px; margin-right: 0px; margin-left: 0px;}
  #whatwedoicons {width:280px;}
  #processicons {width:280px;}
  #statsicons {width:280px;}
  .contactsection {margin-right: 0px;}
  #arrows { display: none;}
  .smallseparatecontact {width: 300px;}
  .smallseparatecontact2 {width:300px;}
  .portraitsocialicons {margin-bottom: 20px;}
  .portraitlast {float: none;}
  .servicesitemlast {margin-right: 0px; float:left; width: 300px; margin-top:20px;}
  #ipadmap {display: none;}
  #checklist { width:300px;}
  .checkmarktext {width: 270px;}
  .checklistitem {width: 300px;}
  #hiddenmenu ul { display: none; }
  #hiddenmenu select { display: inline-block; font-size:18px; height:33px; font-family: 'Source Sans Pro', sans-serif; margin-bottom: 0px; }
  #iniciomenu select { width: 240px; display: inline-block; font-size:18px; height:33px; font-family: 'Source Sans Pro', sans-serif; margin-bottom: 0px; }
  #menuband select { width: 230px; display: inline-block; font-size:18px; height:33px; font-family: 'Source Sans Pro', sans-serif; margin-bottom: 0px; }
  #menuband {width: 290px; height: 45px;}
  #inicioband {width: 100%; height: 50px;}
  #menu1 {display: none;}
  .dottedline_right { width: 85px;}
  .dottedline_left { width: 85px;}
  .smallheadingfont {font-size: 42px;}
  #experiences { display: inline-block; margin-top: 6px;}


}

@media only screen and (min-width: 401px) and (max-width: 767px) {
  .container {width: 300px;}
  .galleryitem {width: 300px; float: none; padding-right: 0px; padding-left: 0px; margin:0 auto;}
  .galleryitemdescription {width: 315px; margin: 0 auto;}
  .servicesitem {width: 300px; float: none;}
  .servicesitemlast {width: 300px; float: none;}
  #hiddenmenu { font-size: 6px;}
  #clients { display: none; }
  #testimonials { width: 300px;}
  #whatwedo {width: 300px;}
  #process {width: 300px;}
  #stats { width: 300px;}
  .portrait {margin: 0 auto; float: none;}
  .blurb {width: 300px;}
  .blurbenclosure{width: 300px;}
  .blurbenclosure2{width: 300px;}
  .aboutusblurb {width: 300px;}
  .contactleft {margin: 0 auto; float: none;}
  .contact_middle {width: 300px; margin: 0 auto; float: none; padding-left: 0px; padding-right: 0px; margin-top:60px;}
  #textleft {width: 300px; float: none; text-align: center; margin: 0 auto; border: none; padding-bottom: 30px; padding-right: 0px;}
  #textright {width: 300px; float: none; text-align: center; margin: 0 auto; border: none; padding-left: 0px;}
  #testimonials_split_text {width: 300px;}
  .line { width: 300px;}
  .aboutusblurb {width: 300px;}
  #skillwrapper {width: 300px; padding-bottom: 45px; margin: 0 auto;}
  .skilltitle {margin-left:0px;}
  #aboutusright {width: 300px; float: none; margin: 0 auto;}
  #aboutusleft {width: 300px; float: none; margin: 0 auto;}
  #contactbutton {width: 300px; margin: 0 auto;}
  #iniciomenu {width: 300px; font-size: 9px;}
  .introh1 {font-size: 60px; margin-bottom: 9px; margin-bottom: 9px;}
  .introh3 {font-size: 16px; margin-bottom: 0px;}
  .introh4 {width: 300px; font-size: 21px; padding-bottom: 0px; padding-top: 4px;}
  h1 {font-size: 60px;}
  #hiddenmenu {margin-top: 44px;}
  #navcover {height: 50px; margin-top: -58px;}
  .lineleft1 {width: 100px; margin-left: 0px; margin-right: 0px;}
  .lineright1 {width: 100px; margin-right: 0px; margin-left: 0px;}
  .lineleft2 {width: 100px; margin-left: 0px; margin-right: 0px;}
  .lineright2 {width: 100px; margin-right: 0px; margin-left: 0px;}
  .lineleft3 {width: 100px; margin-left: 0px; margin-right: 0px;}
  .lineright3 {width: 100px; margin-right: 0px; margin-left: 0px;}
  .lineleft4 {width: 100px;margin-left: 0px; margin-right: 0px;}
  .lineright4 {width: 100px; margin-right: 0px; margin-left: 0px;}
  .lineleft5 {width: 70px;margin-left: 0px; margin-right: 0px;}
  .lineright5 {width: 70px; margin-right: 0px; margin-left: 0px;}
  #whatwedoicons {width:300px;}
  #processicons {width:300px;}
  #statsicons {width:300px;}
  .contactsection {margin-right: 0px; width: 300px;}
  #arrows { display: none;}
  .smallseparatecontact {width: 300px;}
  .smallseparatecontact2 {width:300px;}
  .portraitsocialicons {margin-bottom: 20px;}
  .portraitlast {float: none;}
  .servicesitemlast {margin-right: 0px; float:left; width: 300px; margin-top:20px;}
  #ipadmap {display: none;}
  #checklist { width:300px;}
  .checkmarktext {width: 270px;}
  .checklistitem {width: 300px;}
  #hiddenmenu ul { display: none; }
  #hiddenmenu select { display: inline-block; font-size:18px; height:33px; font-family: 'Source Sans Pro', sans-serif; margin-bottom: 0px; }
  #iniciomenu select { display: inline-block; font-size:18px; height:33px; font-family: 'Source Sans Pro', sans-serif; margin-bottom: 0px; }
  #iniciomenu ul {display: none;}
  #iniciomenu {border:none;}
  .dottedline_right { width: 85px;}
  .dottedline_left { width: 85px;}
  #menuband {width: 350px;}
  #menu1 {width: 350px;}
  #menu1 li {font-size: 11px; margin-left: 10px;}
  .contactsection {width: 100%;}
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .container {width: 700px;}
  .galleryitem {width: 700px; float: none; padding-right: 0px; padding-left: 0px; margin:0 auto;}
  .galleryitemdescription {width: 315px; margin: 0 auto;}
  .servicesitem {width: 700px; float: none;}
  .servicesitemlast {width: 700px; float: none;}
  #clients { display: none; }
  #testimonials { width: 700px;}
  #whatwedo {width: 700px;}
  #process {width: 700px;}
  #stats { width: 700px;}
  .portrait {margin: 0 auto; float: none;}
  .blurb {width: 700px;}
  .blurbenclosure{width: 700px;}
  .blurbenclosure2{width: 700px;}
  .aboutusblurb {width: 700px;}
  .contactleft {margin: 0 auto; float: none;}
  .contact_middle {width: 300px; margin: 0 auto; float: none; padding-left: 0px; padding-right: 0px;}
  #textleft {width: 300px;}
  #textright {width: 299px;}
  #testimonials_split_text {width: 700px;}
  .line { width: 300px;}
  .aboutusblurb {width: 300px;}
  #skillwrapper {width: 300px; padding-bottom: 45px; margin: 0 auto;}
  #aboutusright {width: 300px; float: right;}
  #aboutusleft {width: 300px; float: left;}
  #contactbutton {width: 400px; margin: 0 auto;}
  #iniciomenu {width: 590px; font-size: 13px;}
  #iniciomenu ul li {margin-right:30px;}
  #hiddenmenu {margin-top: 44px;}
  #navcover {height: 30px;}
  .lineleft1 {width: 100px; margin-left: 0px; margin-right: 0px;}
  .lineright1 {width: 100px; margin-right: 0px; margin-left: 0px;}
  .lineleft2 {width: 185px; margin-left: 0px; margin-right: 10px;}
  .lineright2 {width: 185px; margin-right: 0px; margin-left: 10px;}
  .lineleft3 {width: 225px; margin-left: 0px; margin-right: 10px;}
  .lineright3 {width: 225px; margin-right: 0px; margin-left: 10px;}
  .lineleft4 {width: 225px;margin-left: 0px; margin-right: 10px;}
  .lineright4 {width: 225px; margin-right: 0px; margin-left: 10px;}
  .lineleft5 {width: 250px;margin-left: 0px; margin-right: 10px;}
  .lineright5 {width: 250px; margin-right: 0px; margin-left: 10px;}
  #whatwedoicons {width:700px;}
  #processicons {width:700px;}
  #statsicons {width:400px;}
  .contactsection {margin-right: 0px; width: 400px;}
  #arrows { display: none;}
  .smallseparatecontact {width: 300px;}
  .smallseparatecontact2 {width:300px;}
  .portraitsocialicons {margin-bottom: 20px;}
  .portraitlast {float: none;}
  .servicesitemlast {margin-right: 0px; float:left; width: 700px; margin-top:20px;}
  #ipadmap {display: none;}
  #checklist { width:300px;}
  .checkmarktext {width: 270px;}
  .checklistitem {width: 300px;}
  .skilltitle {margin-top:35px;}
  #contactbutton {width: 700px;}
  .contactleft {width: 300px; height: auto; float: left; text-align: center; margin-left: 0px;}
  .contact_middle{width: 300px; float: right; padding-left: 0px; padding-right: 0px; margin-top: -4px;}
  .contactitems {margin-bottom:36px;}
  #hiddenmenu a {text-decoration: none; color: #000; padding-right: 45px; display: inline-block; cursor: pointer;}
  .contactsection {width: 100%;}



}

#hiddenmenu {
  display: none;
}

#menu {
  padding: 0px !important;
}

.sticky-visible {
  display: block !important;
}

#copyright {
  color: #FFF;
  margin-left: 10px;
  -webkit-margin-before: 0px !important;
  -webkit-margin-after: 0px !important;
}

#copyright a {
  color: #FFF;
  text-decoration: none;
}

#copyright a:hover {
  text-shadow: 1px 0px 3px #fff !important;
}

#logo img {
  max-width: 150px;
  margin-top: -10px;
}
