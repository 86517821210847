.github-ribbon {
position: fixed;
top: 0;
right: 0;
border: 0;
width: 149px !important;
height: 149px !important;
z-index: 9999;
}

.github {
display: none;
}

@media (min-width: 1024px) {
    .github {
        display: block;
    }
}

@media (max-width: 1024px) {
    .github {
        display: none;
    }
}